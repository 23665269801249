import { ReactNode } from 'react'

export interface Props {
  children: ReactNode
}

export const useBadge = (props: Props) => {
  const { children } = props

  return {
    children,
  }
}
